/*
* 업무구분: 홈메뉴설정
* 화 면 명: MSPBC010P
* 화면설명: 홈메뉴설정
* 접근권한: 
* 작 성 일: 2025.02.28
* 작 성 자: 박상진
*/
<template data-page-screen-size="300x500">
  <ur-page-container title="홈메뉴 설정" :show-title="false" type="popup" class="msp">
    <ur-box-container direction="column" componentid="" align-v="start" class="msp-cm-wrap">
      
      <ur-box-container align-v="center" componentid="" direction="row" ref="_modal__title" class="ns-bs-title-login">
        <div class="title"><ur-text componentid="" class size="lg">홈메뉴 설정</ur-text></div>
        <div class="btn-close" @click="fn_Close()">X</div>
      </ur-box-container>

      
          <div class="homeMenuConfigWrap">
            <div class="homeMenuConfigGuide1">메뉴순서를 쉽게 변경할 수 있어요. 메뉴를 꾹 누른 후 끌어서 위치를 변경해 보세요.</div>
            <div class="homeMenuConfigGuide2" v-if="!isManager">고객등록동의, 전체고객, 가입보장설계, 보유계약은 필수메뉴로 수정이 불가합니다.</div>
            <div class="homeMenuConfigArea">
              <mo-draggable-list v-model="lv_myMenuList" column="3" :drag-image-style="dragImageStyle" >
                <template #default="{item, index}">
                  <div class="homeMenuConfigItem" :class="{select:item.select}">
                    <span class="homeMenuConfigItemLabel">{{item.menuNm}}</span>
                    <div class="homeMenuConfigItemBottom">
                      <mo-checkbox :ref="'checkbox' + index" v-model="lv_checkedItems" :value="item.menuId" @click="fn_OnClickCheck" @input="fn_OnInputCheck" :disabled="item.fixed === 'Y'"></mo-checkbox>
                      <img class="homeMenuConfigItemIcon" :src="item.imgSrc">
                    </div>
                  </div>
                </template>
                <template #handle="{item}">
                  <div class="homeMenuConfigHandle"  @touchstart="e=>onDragStart(e, item)">
                    <mo-icon class="iconDragHandle">equal</mo-icon>
                  </div>
                </template>
              </mo-draggable-list>
            </div>
            <div class="homeMenuConfigGuide" v-if="showGuideImage===true" @click="showGuideImage=false"></div>
            <div id="dragImageWrap"></div><!-- 2025.3.14 :chan -->
          </div>

          <!-- <template v-slot:action> -->
            
          <!-- </template> -->
        <!-- </mo-bottom-sheet> -->
        <!-- 본문내용 끝 -->
            <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
              <div class="relative-div">
                <mo-button componentid="mo_button_006" color="normal" shape="solid" size="medium" class="ns-btn-round white" @click="$emit('closed')">취소</mo-button>
                <mo-button componentid="mo_button_007" color="normal" shape="solid" size="medium" class="ns-btn-round skyblue" @click="fn_OpenMSPBC011P">메뉴추가</mo-button>
                <mo-button componentid="mo_button_008" color="normal" shape="solid" size="medium" class="ns-btn-round blue" @click="fn_InsHomMenuHypt">저장</mo-button>
              </div>
            </ur-box-container>
      
    </ur-box-container>
  </ur-page-container>

</template>
<script>
import MSPBC011P from '@/ui/bc/MSPBC011P.vue'
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPBC010P",
    screenId: "MSPBC010P",
    components: {},
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    props: {},
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      const lv_Vm = this
      
      lv_Vm.fn_SelListHomMenu()

      // 지점장 여부 확인
    if (this.getStore('userInfo').getters.getUserInfo.userRoleList.indexOf('F110') > -1) {
      this.isManager = true
    }
      // lv_Vm.savedHomeMenuList.forEach(e => {
      //   console.log(e.srnId)
      //   console.log('MSPCM050M|MSPCM115M|MSPPI700M|MSPPM902M'.indexOf(e.srnId))
      //   if('MSPCM050M|MSPCM115M|MSPPI700M|MSPPM902M'.indexOf(e.srnId) >= 0){
      //     e.fixed = 'Y'
      //   }else{
      //     e.fixed = 'N'
      //   }
      //   e.itemSel = 'Y'
      // });
      // lv_Vm.lv_checkedItems = lv_Vm.savedHomeMenuList.map(item => item.menuId)
      // // this.lv_checkedItems = this.savedHomeMenuList
      // console.log(this.lv_checkedItems)
      // lv_Vm.lv_myMenuList = lv_Vm.savedHomeMenuList
      
      // console.log('saveHomeMenuList : ', lv_Vm.savedHomeMenuList)
      // console.log('lv_myMenuList : ', lv_Vm.lv_myMenuList)
    },
    mounted () {
      // Alert 노출(임시)
      // this.$refs.nsbottomsheet.open()
      setTimeout(()=>{this.showGuideImage = false}, 15000) // drag guide image 7초 후 자동 사라짐
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_IsOnlyChecked: false,
        lv_homMenuList: [],
        lv_checkedItems: [],
        itemList:[
          {idx:0, title: 'UPPER', text: 'ITEM1', fixed: 'Y'},
          {idx:1, title: 'UPPER', text: 'ITEM2'},
          {idx:2, title: 'UPPER', text: 'ITEM3'},
          {idx:3, title: 'UPPER', text: 'ITEM4'},
        ],
        startList: undefined,
        lv_myMenuList: [],
        dragImageStyle:{
          opacity:'0%'
        },
        showGuideImage:true,
        isManager:false,
        shadowMenuList:[] // 2025.2.24 :chan
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      fn_GetHomeMenuList() {
        return this.lv_IsOnlyChecked ? this.fn_GetCheckedMenuList() : this.lv_homMenuList
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {      
      openPop() { this.$refs.nsbottomsheet.open() },
      closePop() { this.$refs.nsbottomsheet.close() },
      fn_GetCheckedMenuList() {
        return this.lv_myMenuList.filter(d => this.lv_checkedItems.includes(d.menuId))
        // return this.lv_homMenuList
        //   .filter(item => this.lv_checkedItems.includes(item.menuId))
        //   .sort((a, b) => this.fn_GetCheckedNum(a) > this.fn_GetCheckedNum(b) ? 1 : -1)
      },
      fn_OnClickListItem(e, index) {
        debugger
        const checkbox = this.$refs['checkbox' + index]
        if (checkbox.disabled) return
        checkbox.onClick()
      },
      fn_OnClickCheck() {
        this.lv_checkedItemsBak = _.clone(this.lv_checkedItems)
      },
      fn_OnInputCheck() {
        if (this.lv_checkedItems.length > 24) {
          this.lv_checkedItems.pop()
          this.$showSnackbar('홈메뉴는 24개까지 설정하실 수 있습니다.')
        } else if (this.lv_checkedItems.length < 3) {
          this.lv_checkedItems = this.lv_checkedItemsBak
          this.$showSnackbar('홈메뉴는 3개 이상 설정하셔야 합니다.')
        }
      },
      /*********************************************************
       * Function명: fn_InsHomMenuHypt
       * 설명: 홈 설정 메뉴 등록
       *********************************************************/
      fn_InsHomMenuHypt() {

        const pParams = {
          homMenuHyptList: this.fn_GetCheckedMenuList()
        }
        //test
        console.log('MSPBC011P : ', this.fn_GetCheckedMenuList() )
        
        const trnstId = 'txTSSBC40U1'
        return this.post(this, pParams, trnstId, 'S').then(response => {
          if (response?.body) {
            const body = response.body
            if (!body.rsltFlag) {
              this.$addSnackbar('오류가 발생했습니다.')
              return
            }
            this.$emit('saved')
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },
      fn_OpenMSPBC011P() {
        const popMSPBC011P = this.$bottomModal.open(MSPBC011P, {
          properties: {
            savedHomeMenuList : this.lv_myMenuList
          },
          listeners: {
            addClosed: () => {
              console.log("011P close")
              this.$bottomModal.close(popMSPBC011P)
            },
            addSaved: () => {
              this.fn_SelListHomMenu()
              // 홈메뉴
              this.$emit('reloaded')
              
              this.$bottomModal.close(popMSPBC011P)
            }
          }
        })
      },
      fn_Close() {
        this.$emit('closed')
      },
      /*********************************************************
       * Function명: fn_SelListHomMenu
       * 설명: 홈메뉴
       *********************************************************/
      fn_SelListHomMenu() {
        let lv_Vm = this
        const pParams = {}
        const trnstId = 'txTSSBC40S6'
        // const trnstId = 'txTSSBC40S9'
        let newMenuList = []
        // try {
        //   newMenuList = await this.$commonUtil.selMgrCfg(this, {envId: 'MENU_NEW_LABEL_LIST'}) // 메뉴신규라벨목록
        // } catch (e) {
        //   console.log('MSPBC002M, selMgrCfg - error >', e)
        // }
        return lv_Vm.post(lv_Vm, pParams, trnstId, 'S').then(response => {
          if (response?.body) {
            const body = response.body
            if (!body.rsltFlag) {
              lv_Vm.$addSnackbar('오류가 발생했습니다.')
              return
            }
            if (!body.homMenuList) {
              return
            }

            //홈메뉴 이미지/새로운 설정[2025-02-19]
            lv_Vm.lv_homMenuList = lv_Vm.$bcUtil.setMenuImg(body.homMenuList, newMenuList)

            lv_Vm.lv_homMenuList.forEach(e => {
              console.log(e.srnId)
              console.log('MSPCM050M|MSPCM115M|MSPPI700M|MSPPM902M'.indexOf(e.srnId))
              // if('MSPCM050M|MSPCM115M|MSPPI700M|MSPPM902M'.indexOf(e.srnId) >= 0){
              if(e.menuFixYn){
                e.fixed = 'Y'
              }else{
                e.fixed = 'N'
              }
              e.itemSel = 'Y'
            });
            lv_Vm.lv_checkedItems = lv_Vm.lv_homMenuList.map(item => item.menuId)
            // this.lv_checkedItems = this.savedHomeMenuList
            console.log(lv_Vm.lv_homMenuList)
            lv_Vm.lv_myMenuList = lv_Vm.lv_homMenuList
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },
      onDragStart(event, disabled) { 
        // 수정 2025.3.14 :chan 
        // 목표 대상의 drage image 생성 후 drag 진행 중 image 표출
        if(disabled.fixed === 'Y'){
          event.stopPropagation()
          return
        }
        let cloneTarget = event.currentTarget.parentElement.parentElement
        let clone = cloneTarget.cloneNode(true)
        let cloneWidth = cloneTarget.clientWidth
        let cloneHeight = cloneTarget.clientHeight
        cloneTarget.style.opacity = 0
        let dragArea = document.querySelector('.homeMenuConfigArea')
        let dragImageWrap = document.querySelector('#dragImageWrap')
        dragImageWrap.style.width = cloneWidth + 'px'
        dragImageWrap.style.height = cloneHeight + 'px'
        dragArea.addEventListener('touchstart', function(e) {
          let dragEnableClass = ['homeMenuConfigHandle', 'iconDragHandle']
          let dragEnableTag = ['rect', 'svg']
          if(e.target.classList[0] === undefined){
            if(dragEnableTag.includes(e.target.tagName) === false){
              cloneTarget.style.opacity = 1
              return
            }
          } else if(dragEnableClass.includes(e.target.classList[0]) === false){
            cloneTarget.style.opacity = 1
            return
          }
          dragImageWrap.style.display = 'block'
          dragImageWrap.style.left = e.changedTouches[0].pageX - 80 + "px"
          dragImageWrap.style.top = e.changedTouches[0].pageY - 30 + "px"
          while(dragImageWrap.hasChildNodes()){
            dragImageWrap.removeChild(dragImageWrap.firstChild)
          }
          dragImageWrap.appendChild(clone)
        })
        dragArea.addEventListener('touchmove', function(e) {
          dragImageWrap.style.left = e.changedTouches[0].pageX  - 80 + "px"
          dragImageWrap.style.top = e.changedTouches[0].pageY -30 + "px"
        })
        dragArea.addEventListener('touchend', function(e) {
          dragImageWrap.style.display = 'none'
          cloneTarget.style.opacity = 1
        })
      },
      onDragEnd(e, fromItemIndex, fromListId, targetItemIndex, targetItemTopBottom){ // 2025.2.24 :chan
        if(this.shadowMenuList[targetItemIndex].disabled 
        || (this.shadowMenuList[targetItemIndex - 1]?.disabled && targetItemTopBottom === 'top'
        && this.shadowMenuList[targetItemIndex + 1]?.disabled && targetItemTopBottom === 'bottom')){
          this.menuList = this.shadowMenuList // drag 불가 시 되돌림
        } else {
          this.shadowMenuList = this.menuList // drag 성공 시 shadow list에 같이 저장
        }
      },
    }
  };
</script>