/*
* 업무구분: 홈메뉴설정
* 화 면 명: MSPBC011P
* 화면설명: 홈메뉴설정 메뉴추가
* 접근권한: 
* 작 성 일: 2025.02.28
* 작 성 자: 박상진
*/
<template>
  <ur-page-container title="홈메뉴 설정 선택" :show-title="false" class="msp" type="popup">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      
        <ur-box-container align-v="center" componentid="" direction="column" ref="_modal__title" class="ns-bs-title-login">
          <div class="title"><ur-text componentid="" class size="lg">홈메뉴 설정 선택</ur-text></div>
          <div class="btn-close" @click="fn_Close()">X</div>
        </ur-box-container>
    </ur-box-container>
        <!-- 본문내용 -->
        <!-- <ur-box-container align-v="start" componentid="" direction="column" class="mb30 bs-h-default"> -->
        <!-- <mo-bottom-sheet ref="nsbottomsheet" :close-btn="true" class="ns-bottom-sheet"> -->
          <!-- <template v-slot:title>홈메뉴 추가</template> -->
          <div class="homeMenuSelectWrap">
            <div v-for="(list, index) in lv_menuList" :key="index">
              <div class="homeMenuSelectSubject">{{list.category}}</div>
              <div class="homeMenuSelectList">
                <div v-for="(item, idx) in list.menu" :key="idx" class="homeMenuSelectItem">
                  <div class="homeMenuConfigItemLabel">{{item.menuNm}}</div>
                  <!-- <div class="homeMenuSelectComment">{{item.comment}}</div> -->
                  <div class="homeMenuSelectIconWrap">
                    <!-- <mo-checkbox v-model="item.select" :disabled="item.disabled"></mo-checkbox> -->
                    <mo-checkbox :ref="'checkbox' + idx" v-model="lv_checkedItems" :value="item.menuId" @click="fn_OnClickCheck" @input="fn_OnInputCheck" :disabled="item.menuFixYn === 'Y'" :class="'num' + fn_GetCheckedNum(item)"></mo-checkbox>
                    <img class="homeMenuConfigItemIcon" :src="item.imgSrc">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <template v-slot:action> -->
            
          <!-- </template> -->
        <!-- </mo-bottom-sheet> -->
        <!-- 본문내용 끝 -->
        <!-- </ur-box-container> -->
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
              <div class="relative-div">
                <mo-button componentid="mo_button_006" color="normal" shape="solid" size="medium" class="ns-btn-round white" @click="fn_Close()">취소</mo-button>
                <mo-button componentid="mo_button_008" color="normal" shape="solid" size="medium" class="ns-btn-round blue" @click="fn_InsHomMenuHypt">확인</mo-button>
              </div>
            </ur-box-container>
      <!-- </ur-box-container> -->
    
  </ur-page-container>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPBC011P",
    screenId: "MSPBC011P",
    components: {},
    props: {
      savedHomeMenuList: {
        type: Array,
      },
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.fn_SelListHomHyptMenu()

      console.log('MSPBC011P savedHomeMenuList: ', this.savedHomeMenuList)
      console.log('MSPBC011P lv_homMenuList: ', this.lv_homMenuList)
    },
    mounted () {
      // Alert 노출(임시)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        radioValue:'',
        lv_menuList: [],
        lv_IsOnlyChecked: false,
        lv_homMenuList: [],
        lv_checkedItems: []
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_Close() {
        console.log('fn_close')
        this.$emit('addClosed')
      },
      /*********************************************************
       * Function명: fn_SelListHomHyptMenu
       * 설명: 홈 설정 메뉴 조회
       *********************************************************/
      fn_SelListHomHyptMenu() {
        let tempObject = []
        let lv_Vm = this
        const pParams = {}
        const trnstId = 'txTSSBC40S9'
        return this.post(this, pParams, trnstId, 'S').then(response => {
          if (response?.body) {
            const body = response.body
            if (!body.rsltFlag) {
              lv_Vm.$addSnackbar('오류가 발생했습니다.')
              return
            }
            if (!body.homMenuList) {
              return
            }
            lv_Vm.lv_homMenuList = body.homMenuList.filter(item => !!item.menuId)
            // 전체 메뉴
            lv_Vm.lv_checkedItems = body.homMenuList?.filter(item => item.menuFixYn == 'Y').map(item => item.menuId) || []
            // 설정된 메뉴 merge
            lv_Vm.lv_checkedItems = lv_Vm.lv_checkedItems.concat(lv_Vm.savedHomeMenuList?.map(item => item.menuId)).filter(Boolean)
            // 중복제거
            lv_Vm.lv_checkedItems = [...new Set(lv_Vm.lv_checkedItems)]
            // tempObject = Object.groupBy(lv_Vm.lv_homMenuList, ({upMenuNm}) => upMenuNm)

            let newArrList = []

            //상위메뉴명만 취득
            lv_Vm.lv_homMenuList.forEach(lv1 => {
              newArrList.push(lv1.upMenuNm)
            })
            //상위메뉴명 중복제거
            newArrList = [...new Set(newArrList)]

            //메뉴이미지 매핑
            console.log('before : ', lv_Vm.lv_homMenuList)
            lv_Vm.lv_homMenuList = lv_Vm.$bcUtil.setMenuImg(lv_Vm.lv_homMenuList)
            console.log('after : ', lv_Vm.lv_homMenuList)
            
            //메뉴목록생성
            let menuList = []
            newArrList.forEach(x => {
              let newObject = {}
              // console.log(x)
              newObject.category = x
              newObject.menu = lv_Vm.lv_homMenuList.filter(ex => ex.upMenuNm === x)
              // Object.assign(x,newObject)
              lv_Vm.lv_menuList.push(newObject)
            })
            
            // lv_Vm.lv_menuList = Array.from(tempObject, ({key, value}) => ({key,value}))
            // let oKeys = Object.keys(tempObject)

            // let tArr = []
            // oKeys.forEach(x => {
            //   let tObj = {}
            //   console.log(lv_Vm.lv_homMenuList.filter(ex => ex.upMenuNm === x));
            //   tObj.category = x
            //   tObj.menu = lv_Vm.lv_homMenuList.filter(ex => ex.upMenuNm === x)

            //   lv_Vm.lv_menuList.push(tObj)
            // })
            // console.log('!TEEEEET!! : ', tArr)
            // console.log('jjifif', lv_Vm.menuList)
            // console.log('lv_Vm.lv_menuList : ', lv_Vm.lv_menuList)
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },
      fn_OnClickCheck() {
        this.lv_checkedItemsBak = _.clone(this.lv_checkedItems)
        console.log('fn_OnClickCheck : ', this.fn_GetCheckedMenuList())
      },
      fn_OnInputCheck() {
        if (this.lv_checkedItems.length > 24) {
          this.lv_checkedItems.pop()
          this.$showSnackbar('홈메뉴는 24개까지 설정하실 수 있습니다.')
        } else if (this.lv_checkedItems.length < 3) {
          this.lv_checkedItems = this.lv_checkedItemsBak
          this.$showSnackbar('홈메뉴는 3개 이상 설정하셔야 합니다.')
        }
      },
      /*********************************************************
       * Function명: fn_InsHomMenuHypt
       * 설명: 홈 설정 메뉴 등록
       *********************************************************/
      fn_InsHomMenuHypt() {
        const pParams = {
          homMenuHyptList: this.fn_GetCheckedMenuList()
        }
        //test
        console.log('MSPBC011P : ', this.fn_GetCheckedMenuList() )
        const trnstId = 'txTSSBC40U1'
        return this.post(this, pParams, trnstId, 'S').then(response => {
          if (response?.body) {
            const body = response.body
            if (!body.rsltFlag) {
              this.$addSnackbar('오류가 발생했습니다.')
              return
            }
            this.$emit('addSaved')
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },
      fn_GetCheckedMenuList() {
        return this.lv_homMenuList
          .filter(item => this.lv_checkedItems.includes(item.menuId))
          .sort((a, b) => this.fn_GetCheckedNum(a) > this.fn_GetCheckedNum(b) ? 1 : -1)
      },
       fn_GetCheckedNum(item) {
        return this.lv_checkedItems.indexOf(item.menuId) + 1
      },
    }
  };
</script>